import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../components/seo'
import Layout from '../components/layout'
// import useSite from '../hooks/use-site'
import styled from 'styled-components'
import PortableText from '../components/richText'
import arrowImg from '../assets/images/shopArrow.svg'


export const query = graphql`
  query BulletinPageQuery {
    bulletin: sanityBulletin {
      title
      tagline
      sideImage {
        alt
        asset {
          _id
          fixed(width: 500) {
            ...GatsbySanityImageFixed
          }
        }
      }
      quote {
        alt
        asset {
          _id
          fixed(width: 400) {
            ...GatsbySanityImageFixed
          }
        }
      }
      text {
        _key
        _type
        style
        list
        children {
          _key
          _type
          text

        }
      }
      image {
        alt
        asset {
          _id
          fixed(width: 250) {
            ...GatsbySanityImageFixed
          }
        }
      }
    }
  }
`

const BulletinStyles = styled.div`
  .side-image {
    left: -400px;
    top: 220px;
    z-index: -10;
  }

  .oval-image {
    margin-right: -50px;
  }

  .portable-text {
    .newsletter-link,
    .shop-link {
      text-decoration: underline;
    }
  }

  .shop-link {
    &:after {
      content: ".";
      display: inline-block;
    }
  }

  @media screen and (min-width: 768px) {
    .shop-link {
      display: inline-block;
      position: absolute;
      transform: rotate(4deg);
      &:before {
        content: url(${arrowImg});
        display: inline-block;
        margin: .75rem;
      }
      &:after {
        display: none;
      }
    }
  }
  @media screen and (min-width: 1024px) {
    .oval-image {
      margin-top: -50px;
      margin-right: 0;
    }

    .shop-link {
      text-decoration: none;
    }
  }
`;

const PortableStyles = styled.div`
  a {
    text-decoration: underline;
  }
  p {
    &:last-of-type {
      margin-top: 4rem;
      @media screen and (min-width: 1024px) {
        margin-top: 2rem;
      }
    }
  }

`;

const BulletinPage = ({ data }) => {
  // const site = useSite();

  // page data
  const bulletinData = data.bulletin
  console.log(bulletinData)
  const sideImage = bulletinData.sideImage?.asset
  const quoteImage = bulletinData.quote?.asset
  const image = bulletinData.image?.asset


  return (
    <Layout>
      <SEO />
      <BulletinStyles className="bulletin-main flex flex-col lg:flex-row justify-center items-center h-full md:px-10 lg:py-10 lg:px-40 overflow-x-hidden">
        <div className="side-image flex justify-center w-auto lg:w-1/2 lg:h-full lg:pr-4 absolute lg:static">
          {sideImage && <Img fixed={sideImage.fixed} alt={sideImage.alt} />}
        </div>
        <div className="w-full lg:w-1/2 flex flex-col lg:pr-4">
          <h2 className="serif text-2xl pt-8 lg:pt-16 py-3 text-center lg:text-left">{bulletinData.tagline}</h2>
          {quoteImage && <Img className="quote-image self-end my-3" fixed={quoteImage.fixed} alt={quoteImage.alt} />}

          <PortableStyles className="portable-text serif text-2xl py-3 pl-32 pr-4 lg:pl-0 lg:pr-0 z-10">
            <PortableText blocks={bulletinData.text} />
          </PortableStyles>


          {/* <div className="portable-text serif text-2xl py-3 pl-32 pr-4 lg:pl-0 lg:pr-0">
            <p>We will open in the Spring of 2021.</p>
            <p>Subscribe to our <a className="newsletter-link" href="#">newsletter</a> for more updates.</p>
            <p>In the meantime, check out our <a className="shop-link" href="#">shop</a></p>
            <p className="pt-6">— Nolan &amp; Trevor</p>
          </div> */}

          {image && <Img className="oval-image object-contain self-end py-3" fixed={image.fixed} alt={image.alt} />}
        </div>
      </BulletinStyles>
    </Layout>
  )
}
export default BulletinPage
